import {useNavigate} from 'react-router-dom'
import { useState } from 'react';

export const Login = () => {
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("");

    const navigate = useNavigate()

    const login = (password) => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ password: password }),
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        };
        var res = fetch(`${process.env.REACT_APP_API}/auth`, requestOptions)
        res.then(function (res) {
            if (res.status === 200) {
                navigate("/buchung")
            } else if (res.status === 401) {
                setStatus("Falsches Passwort")
            } else {
                setStatus("Es ist ein Fehler aufgetreten")
            }
        })
    }


    return (
        <div className='loginDiv'>
            <p className='loginText'>Passwort eingeben</p>
            <p className='statusLoginText'>{status}</p>
            <input type="password" className="input" autoFocus placeholder="Passwort eingeben" onChange={e => setPassword(e.target.value)} value={password} />
            <p> <button className='loginBtn' onClick={() => login(password)}>Login</button></p>
           
        </div>

    )
}
