import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

export const Bookings = () => {

    const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);
    const [isOpen, setOpen] = useState(0);
    const [status, setStatus] = useState("");
    const [values, setValues] = useState({
        fromDate: new Date(new Date().setHours(12)).toISOString().substring(0, 10),
        toDate: new Date(new Date().setHours(12)).toISOString().substring(0, 10),
        lastname: "",
        firstname: "",
        note: ""
    });

    useEffect(() => {
        if (window.innerWidth < 701) {
            alert("Diese Seite ist nicht für kleine Bildschirme gemacht!")
        }

        const fetchBookings = async () => {
            const requestOptions = {
                method: 'GET',
                credentials: 'include'
            };
            var res = await fetch(`${process.env.REACT_APP_API}/booking`, requestOptions)
            const data = await res.json()
            if (res.status == 401) return navigate("/login");
            setBookings(data)
            console.log(data)
        }
        fetchBookings();
    }, [])

    const createBooking = async () => {
        const requestOptions = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                booking: {
                    fromDate: values.fromDate,
                    toDate: values.toDate,
                    lastname: values.lastname,
                    firstname: values.firstname,
                    note: values.note
                }
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        };
        var res = await fetch(`${process.env.REACT_APP_API}/booking`, requestOptions)
        const data = await res.json()
        if (res.status == 401) return navigate("/login");
        setOpen(0);
        window.location.reload();
        clearValues();
    }
    const editBooking = async () => {
        const response = await axios({
            method: "put",
            url: `${process.env.REACT_APP_API}/booking/${values.id}`,
            withCredentials: true,
            data: JSON.stringify({
                booking: {
                    fromDate: values.fromDate,
                    toDate: values.toDate,
                    lastname: values.lastname,
                    firstname: values.firstname,
                    note: values.note
                }
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if (response.status == 401) return navigate("/login");
        setOpen(0);
        window.location.reload();
        clearValues();
    }
    const deleteBooking = async () => {
        const requestOptions = {
            method: 'DELETE',
            credentials: 'include',
        };
        var res = await fetch(`${process.env.REACT_APP_API}/booking/${values.id}`, requestOptions)
        const data = await res.json()
        if (res.status == 401) return navigate("/login");
        setOpen(0);
        window.location.reload();
        clearValues();
    }

    const clearValues = () => {
        setValues({
            fromDate: new Date(new Date().setHours(12)).toISOString().substring(0, 10),
            toDate: new Date(new Date().setHours(12)).toISOString().substring(0, 10),
            lastname: "",
            firstname: "",
            note: ""
        });
        setStatus("");
    }

    const validateDates = () => {
        try {
            const date1 = new Date(new Date(values.fromDate).setHours(12)).toISOString().substring(0, 10);
            const date2 = new Date(new Date(values.toDate).setHours(12)).toISOString().substring(0, 10);
            setValues({...values, fromDate: date1, toDate: date2})
            return true;
        } catch {
            return false;
        }
    }


    return (
        <div className="bookingsDiv">
            <button className='bookingModalButton' onClick={() => setOpen(1)}>Neue Buchung eintragen</button>
            <div className='bookingModal' style={isOpen > 0 && isOpen < 3 ? {} : { display: "none" }}>
                <p className='modalTitle'>Neue Buchung eintragen</p>
                <p className='modalStatus'>{status}</p>
                <p className='modalLabel'>Vom</p>
                <input className='modalInput' type='date' value={values.fromDate} onChange={e => setValues({ ...values, fromDate: e.target.value })} />
                <p className='modalLabel'>Bis zum</p>
                <input className='modalInput' type='date' value={values.toDate} onChange={e => setValues({ ...values, toDate: e.target.value })} />
                <p className='modalLabel'>Vorname des Gastes</p>
                <input className='modalInput' type='text' value={values.firstname} onChange={e => setValues({ ...values, firstname: e.target.value })} />
                <p className='modalLabel'>Nachname des Gastes</p>
                <input className='modalInput' type='text' value={values.lastname} onChange={e => setValues({ ...values, lastname: e.target.value })} />
                <p className='modalLabel'>Anmerkung (optional)</p>
                <textarea className='modalInput modalTextarea' type='text' maxLength={255} value={values.note} onChange={e => setValues({ ...values, note: e.target.value })} />
                <div style={{ textAlign: "right" }}>
                    <button className='modalBtn modalBtnClose' onClick={() => { setOpen(0); clearValues(); }}>Abbrechen</button><button className='modalBtn modalBtnOk'
                        onClick={() => {
                            if (validateDates()){
                                if (isOpen == 1) createBooking();
                                if (isOpen == 2) editBooking();
                            } else {
                                setStatus("Fehler: Ein Datum scheint nicht richtig formatiert zu sein")
                            }
                            
                        }}>Eintragen</button>
                </div>
            </div>
            <div className='deleteModal' style={isOpen === 3 ? {} : { display: "none" }}>
                <p>Buchung von {values.firstname} {values.lastname} wirklich löschen?</p>
                <button className='modalBtn modalBtnClose' onClick={() => { setOpen(0); clearValues() }}>Abbrechen</button>
                <button className='modalBtn modalBtnOk modalBtnDelete' onClick={() => { deleteBooking() }}>Löschen</button>
            </div>

            <p className='title aboutTitle'>Buchungsverwaltung</p>
            <table><tbody>
                <tr>
                    <td>ID</td>
                    <td>Von</td>
                    <td>Bis</td>
                    <td>Name</td>
                    <td>Aktionen</td>
                    <td>Anmerkung</td>
                </tr>
                {bookings.sort(function (a, b) {
                    return a.fromDate - b.fromDate || b.fromDate.localeCompare(a.fromDate);
                }).map(e => <tr key={e.id}>
                    <td>{e.id}</td>
                    <td>{new Date(e.fromDate).toLocaleDateString()}</td>
                    <td>{new Date(e.toDate).toLocaleDateString()}</td>
                    <td>{e.firstname} {e.lastname}</td>
                    <td style={{ minWidth: "178px" }}><a className='linkEdit' onClick={() => { setOpen(2); setValues({
                        id: e.id,
                        fromDate: new Date(new Date(e.fromDate).setHours(12)).toISOString().substring(0, 10),
                        toDate: new Date(new Date(e.toDate).setHours(12)).toISOString().substring(0, 10),
                        lastname: e.lastname,
                        firstname: e.firstname,
                        note: e.note
                    }) }}>Bearbeiten</a> <a className='linkDelete' onClick={() => { setOpen(3); setValues(e) }}>Löschen</a></td>
                    <td style={{ maxWidth: (window.innerWidth / 4) }}>{e.note}</td>
                </tr>)}
            </tbody></table>
        </div>
    )
}