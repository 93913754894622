import React from 'react'

export const House = ({ divStyle, isMobile }) => {
    return (
        <>
            <div id='houseDiv' className='houseDiv' style={{ ...divStyle, paddingBottom: "10px" }}>
                <p className='title aboutTitle'>Das Haus</p>
                <div className={`houseMapsDiv ${isMobile && 'mobileMapDiv'}`}>

                    <iframe width="100%" height={isMobile ? 250: 400} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d607.6775464435577!2d8.605667504028954!3d63.586212735886086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46125b4104ab3871%3A0x9fd802ea4c637a4f!2sHestnesveien%2055%2C%207250%20Melandsj%C3%B8%2C%20Norwegen!5e1!3m2!1sde!2sde!4v1692620438103!5m2!1sde!2sde" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <p><i className="fa-solid fa-location-dot"></i> Hestnesveien 55, 7250 Melandsjø, Norwegen</p>
                </div>
                <div className={`houseRightDiv ${isMobile && 'mobileHouseDiv'}`}>

                    <p className='houseScrollButton' onClick={() => {
                        var scrollDiv = document.getElementById("galleryDiv").offsetTop;
                        window.scrollTo({ top: scrollDiv - 64, behavior: 'smooth' });
                    }}><i className="fa-solid fa-arrow-up"></i> Galerie</p>
                    <p>Beschreibung</p>
                    <p>Im Erdgeschoß ist ein großes Wohn-Esszimmer mit Blick auf den Fjord, eine Küche mit Esstisch, einem renoviertem Bad mit Dusche und  Waschmaschine.

                        Im Obergeschoß befinden sich 3 Schlafzimmer (2 Zimmer mit  Doppelbetten und ein Zimmer mit Einzelbett).

                        Der große Garten mit Grill lädt im Sommer zu gemütlichen Abenden im Freien ein.


                        Zum Haus gehört ein eigener Bootsanlegeplatz, der ca. 250 m vom Haus entfernt am Fjord liegt, mit einem Boot (30PS Außenborder, 5 Meter) der im Mietpreis des Hauses enthalten ist.</p>
                </div>
                <div className='housePropDiv'>
                    {!isMobile ?
                        <><div className='housePropColumn'>
                            <p className='housePropHeader'><i className="propIconBig fa-solid fa-bath"></i>Badezimmer</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Dusche</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Waschmaschine</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Haartrockner</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>E-Heizung</p>
                            <p className='housePropHeader'><i className="propIconBig fa-solid fa-kitchen-set"></i>Küche</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>E-Herd</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Kühlschrank</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Backofen</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Spülmaschine</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Wasserkocher</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Toaster</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Geschirr und Küchenutensilien</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Kaffee- und Teezubehör</p>
                            <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Esstisch</p>
                        </div>
                            <div className='housePropColumn'>
                                <p className='housePropHeader'><i className="propIconBig fa-solid fa-tv"></i>Medien & Technik</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>TV</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Satellitenempfang</p>
                                <p className='housePropHeader'><i className="propIconBig fa-solid fa-bed"></i>Schlafzimmer</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>3 Schlafzimmer, einmal Einzelbett (90cm), zweimal Doppelbett (140cm)</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Steckdosen in Bettnähe</p>
                                {/*<p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Bettwäsche</p>*/}
                                <p className='housePropHeader'><i className="propIconBig fa-solid fa-campground"></i>Außenbereich</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Grillmöglichkeiten</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Picknickbereich</p>
                                <p className='housePropHeader'><i className="propIconBig fa-solid fa-tree"></i>Aktivitäten</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Zugang zum Bootsanleger (Angeln möglich)</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Gegen Aufpreis können Angelruten ausgeliehen werden, Gefriertruhe ist vorhanden</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Wandern in der Umgebung</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Großer Garten</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Private geführte Bootstouren, gegen Aufpreis und nach Absprache</p>
                            </div>
                            <div className='housePropColumn'>
                                <p className='housePropHeader'><i className="propIconBig fa-solid fa-square-parking"></i>Parkmöglichkeiten</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Private Parkplätze stehen kostenfrei an der Unterkunft zur Verfügung</p>
                                <p className='housePropHeader'><i className="propIconBig fa-solid fa-wifi"></i>Internet</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Zum aktuellen Zeitpunkt gibt es noch kein WLAN, allerdings gibt es Mobilfunkempfang</p>
                                <p className='housePropHeader'><i className="propIconBig fa-solid fa-circle-info"></i>Allgemein</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Aluboot 5m, mit 30 PS Außenbordmotor</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Nichtraucherunterkunft</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Haustiere auf Anfrage</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>E-Heizung</p>
                                <p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Grundstücksfläche 1.150 m²</p>
                            </div></> :

                        <>{/**MOBILE VERSION */}
                        <p className='propTitleMobile'>Einrichtungen</p>
                            <p className='housePropHeader mobileHeaderProp'><i className="propIconMobile fa-solid fa-bath"></i>Badezimmer</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Dusche</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Waschmaschine</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Haartrockner</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>E-Heizung</p>
                            <p className='housePropHeader mobileHeaderProp'><i className="propIconMobile fa-solid fa-kitchen-set"></i>Küche</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>E-Herd</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Kühlschrank</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Backofen</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Spülmaschine</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Wasserkocher</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Toaster</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Geschirr und Küchenutensilien</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Kaffee- und Teezubehör</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Esstisch</p>
                            <p className='housePropHeader mobileHeaderProp'><i className="propIconMobile fa-solid fa-tv"></i>Medien & Technik</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>TV</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Satellitenempfang</p>
                            <p className='housePropHeader mobileHeaderProp'><i className="propIconMobile fa-solid fa-bed"></i>Schlafzimmer</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>3 Schlafzimmer, einmal Einzelbett (90cm), zweimal Doppelbett (140cm)</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Steckdosen in Bettnähe</p>
                            {/*<p className='housePropCheck'><i className="propIcon fa-solid fa-check"></i>Bettwäsche</p>*/}
                            <p className='housePropHeader mobileHeaderProp'><i className="propIconMobile fa-solid fa-campground"></i>Außenbereich</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Grillmöglichkeiten</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Picknickbereich</p>
                            <p className='housePropHeader mobileHeaderProp'><i className="propIconMobile fa-solid fa-tree"></i>Aktivitäten</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Zugang zum Bootsanleger (Angeln möglich)</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Gegen Aufpreis können Angelruten ausgeliehen werden, Gefriertruhe ist vorhanden</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Wandern in der Umgebung</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Großer Garten</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Private geführte Bootstouren, gegen Aufpreis und nach Absprache</p>
                            <p className='housePropHeader mobileHeaderProp'><i className="propIconMobile fa-solid fa-square-parking"></i>Parkmöglichkeiten</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Private Parkplätze stehen kostenfrei an der Unterkunft zur Verfügung</p>
                            <p className='housePropHeader mobileHeaderProp'><i className="propIconMobile fa-solid fa-wifi"></i>Internet</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Zum aktuellen Zeitpunkt gibt es noch kein WLAN, allerdings gibt es Mobilfunkempfang</p>
                            <p className='housePropHeader mobileHeaderProp'><i className="propIconMobile fa-solid fa-circle-info"></i>Allgemein</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Aluboot 5m, mit 30 PS Außenbordmotor</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Nichtraucherunterkunft</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Haustiere auf Anfrage</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>E-Heizung</p>
                            <p className='housePropCheck mobileProp'><i className="propIcon fa-solid fa-check"></i>Grundstücksfläche 1.150 m²</p>
                        </>}

                </div>
                <div className='destinationDiv'>
                    <p className='destinationHeader'>Ziele in der Umgebung</p>
                    <table><tbody>
                        {!isMobile ? <><tr>
                            <td>Bootsanleger</td>
                            <td><i className="destinationicon fa-solid fa-person-walking"></i> 4 Minuten (270m)</td>
                            <td>Supermarkt (REMA in Fillan)</td>
                            <td><i className="destinationicon fa-solid fa-car"></i> 24 Minuten (23,7km)</td>
                        </tr>
                            <tr>
                                <td>Hestneshula (Höhle)</td>
                                <td><i className="destinationicon fa-solid fa-person-walking"></i> 13 Minuten (1km)</td>
                                <td>Trondheim</td>
                                <td><i className="destinationicon fa-solid fa-car"></i> 2 Stunden (147km, Mautgebühren)</td>
                            </tr>
                            <tr>
                                <td>Bushaltestelle (Hestnes)</td>
                                <td><i className="destinationicon fa-solid fa-person-walking"></i> 17 Minuten (1,4km)</td>
                            </tr></> : <>
                            <tr>
                                <td>Bootsanleger</td>
                                <td><i className="destinationicon fa-solid fa-person-walking"></i> 4 Minuten (270m)</td>
                            </tr>
                            <tr>
                                <td>Supermarkt (REMA in Fillan)</td>
                                <td><i className="destinationicon fa-solid fa-car"></i> 24 Minuten (23,7km)</td>
                            </tr>
                            <tr>
                                <td>Hestneshula (Höhle)</td>
                                <td><i className="destinationicon fa-solid fa-person-walking"></i> 13 Minuten (1km)</td>
                            </tr>
                            <tr>
                                <td>Trondheim</td>
                                <td><i className="destinationicon fa-solid fa-car"></i> 2 Stunden (147km, Mautgebühren)</td>
                            </tr>
                            <tr>
                                <td>Bushaltestelle (Hestnes)</td>
                                <td><i className="destinationicon fa-solid fa-person-walking"></i> 17 Minuten (1,4km)</td>
                            </tr>
                        </>}
                    </tbody></table>
                </div>


            </div>
        </>
    )
}
