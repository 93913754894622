import { useState } from "react";

export const Navigation = ({ isMobile }) => {

    const scrollTo = (elementName) => {
        var scrollDiv = document.getElementById(elementName).offsetTop;
        window.scrollTo({ top: scrollDiv - 64, behavior: 'smooth' });
        setIsOpen(false);
    }

    const [isOpen, setIsOpen] = useState(false);

    if (!isMobile) {
        return (
            <div className="navDiv">
                <div className="navLeft">
                    <p className="navTitle">Ferienhaus auf Hitra</p>
                </div>
                <div className="navRight">
                    <p className="navLink" onClick={() => scrollTo("appDiv")}>Start</p>
                    <p className="navLink" onClick={() => scrollTo("aboutDiv")}>Über uns</p>
                    <p className="navLink" onClick={() => scrollTo("galleryDiv")}>Galerie</p>
                    <p className="navLink" onClick={() => scrollTo("houseDiv")}>Das Haus</p>
                    <p className="navLink" onClick={() => scrollTo("contactDiv")}>Kontakt</p>
                    <button className="navButton" onClick={() => scrollTo("bookingDiv")}>Verfügbarkeit und Buchung</button>
                </div>
            </div>
        )
    } else {
        return (
            <div className="navDiv">
                <button onClick={() => setIsOpen(prev => !prev)} className={'mobileNavButton'} id="dropbtn"><i className={`fas fa-bars mtopi`}></i></button>
                <div className={'dropdown'} style={{
                    overflow: "hidden",
                    height: isOpen ? 215 : 0,
                    transition: "0.8s"
                }} id="dropdown">
                    <p className="mobileNavLink" onClick={() => scrollTo("appDiv")}>Start</p>
                    <p className="mobileNavLink" onClick={() => scrollTo("aboutDiv")}>Über uns</p>
                    <p className="mobileNavLink" onClick={() => scrollTo("galleryDiv")}>Galerie</p>
                    <p className="mobileNavLink" onClick={() => scrollTo("houseDiv")}>Das Haus</p>
                    <p className="mobileNavLink" onClick={() => scrollTo("contactDiv")}>Kontakt</p>
                </div>
            </div>
        )
    }
}
