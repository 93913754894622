import React from 'react'

export const AboutUs = ({divStyle, isMobile}) => {
    return (
        <>
        <div id="aboutDiv" className='aboutdiv' style={{...divStyle, marginTop: isMobile && 0}} >

            <p className='title aboutTitle'>Über uns</p>
            <p className='text'>Willkommen in unserem Ferienhaus in Hestnes, einem kleinen idyllischem Ort auf der Insel Hitra mit wunderschönem Blick auf den Fjord.

Das Haus ist mit dem Auto vom Flughafen Trondheim in ca. 2,5 Stunden erreichbar. Die Zufahrt zum Haus und das Parken ist direkt am Haus möglich.  

In Melandsjø (ca. 10 Min mit dem Auto entfernt) gibt es einen kleines Lebensmittelgeschäft in dem man täglich einkaufen kann.

Ansonsten fährt man nach Fillan (ca. 20 Min mit dem Auto entfernt), hier gibt es größere Supermärkte, Restaurants, eine Tankstelle, Banken, Sportgeschäft usw.
</p>
        </div>
        </>
    )
}
