import { useEffect } from "react";

export const Impressum = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <div style={{backgroundColor: "white", height: "100vh", padding: "15px"}}>
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Michael Egger<br />
                P&uuml;trichstrasse 17<br />
                85293 Reichertshausen</p>

            <h2>Kontakt</h2>
            <p>Telefon: 08441 7977766<br />
                E-Mail: info@ferienhaus-hitra.com</p>
                <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
        </div>
    )
}
