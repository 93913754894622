import React from 'react'

export const Slider = ({isMobile}) => {
    return (
        <div style={{textAlign: "center"}}>
            <img className='sliderImg' src='/media/1.jpg' alt='Loading...' />
            <p className={`sliderTitle ${isMobile && 'mobileSliderTitle'}`}>Ferienhaus auf Hitra</p>
            <p className={`sliderText ${isMobile && 'mobileSliderText'}`}>Idyllisches Ferienhaus mit Fjordblick</p>
            <p className={`sliderButton ${isMobile && 'mobileSliderButton'}`} onClick={() => {
                var scrollDiv = document.getElementById("bookingDiv").offsetTop;
                window.scrollTo({ top: scrollDiv - 64, behavior: 'smooth'});
            }}>Verfügbarkeit prüfen</p>
        </div>
    )
}
