import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './style/Navigation.css';
import './style/Slider.css';
import './style/AboutUs.css';
import './style/House.css';
import './style/Gallery.css';
import './style/Contact.css';
import './style/Booking.css';
import './style/Bookings.css';
import './style/Footer.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

