import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate} from 'react-router-dom'
import { AboutUs } from './components/AboutUs';
import { House } from './components/House';
import { Navigation } from './components/Navigation';
import { Slider } from './components/Slider';
import { Gallery } from './components/Gallery';
import { Contact } from './components/Contact';
import { Booking } from './components/Booking';
import { Bookings } from './admin/Bookings';
import { Login } from './admin/Login';
import { Footer } from './components/Footer';
import { Datenschutz } from './law/Datenschutz';
import { Impressum } from './law/Impressum';

function App() {

  //Mobile-Variable
  const [isMobile, setMobile] = useState(false);

  function handleWindowSizeChange() {
    setMobile(window.innerWidth < 1280)
  }

  useEffect(() => {
    //Sets isMobile on resize
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const divStyle = {
    width: isMobile ? "94%" : "60%",
    padding: isMobile ? "5px 3%" : "20px 20%",
  }

  return (
    <div className="app" id='appDiv'>
      <Router><Routes>
        <Route path='/*' element={<>
          {/**Obere Kontakt leiste */}
          <div className="navContactDiv">
              {/*<p className="navContactText"><i className="fa-solid fa-phone navIcon"></i>&nbsp;&nbsp;Telefon:&nbsp;&nbsp;+49 123456789</p>*/}
              <a className="navContactText" href='mailto:info@ferienhaus-hitra.com' style={{cursor: "pointer"}}><i className="fa-solid fa-envelope navIcon"></i>&nbsp;&nbsp;E-Mail:&nbsp;&nbsp;info@ferienhaus-hitra.com</a>
          </div>
          <Navigation isMobile={isMobile}/>
          <Slider isMobile={isMobile}/>
          <AboutUs divStyle={divStyle} isMobile={isMobile}/>
          <Gallery divStyle={divStyle} isMobile={isMobile}/>
          <House divStyle={divStyle} isMobile={isMobile}/>
          <Booking divStyle={divStyle} isMobile={isMobile}/>
          <Contact divStyle={divStyle} isMobile={isMobile}/>
          <Footer/>
        </>} />
        <Route path='/buchung' element={<>
          {/**Buchungsverwaltung*/}
          <Bookings />
          <Footer/>
        </>} />
        <Route path='/login' element={<>
          {/**Login*/}
          <Login />
          <Footer/>
        </>} />
        <Route path='/impressum' element={<>
          {/**Login*/}
          <Impressum />
          <Footer/>
        </>} />
        <Route path='/datenschutz' element={<>
          {/**Login*/}
          <Datenschutz />
          <Footer/>
        </>} />
      </Routes></Router>
    </div>
  );
}

export default App;
