import { useNavigate } from "react-router-dom"

export const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className="footerDiv">
            <p><a onClick={() => navigate('/')}>Startseite</a> - <a onClick={() => navigate('/impressum')}>Impressum</a> - <a onClick={() => navigate('/datenschutz')}>Datenschutz</a></p>
        </div>
    )
}
