import {useState, useEffect} from 'react'
import BookingCalendar from 'react-booking-calendar';
import axios from 'axios'

export const Booking = ({divStyle}) => {
    const [bookings, setBookings] = useState([]);
    const [isOpen, setOpen] = useState(0);

    const [bookedDays, setBookedDays] = useState([]);

    /*const bookedDays = [
        new Date(2023, 2, 18),
        new Date(2023, 2, 20),
    ];*/

    useEffect(() => {
        const fetchBookings = async () => {
            axios.get(`${process.env.REACT_APP_API}/booking/public`)
            .then(response => {
                console.log(response)
                const data = response.data;
                setBookings(data);
                console.log(data)
                let tempBookedDays = [];
                data.map(e => {
                    var getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};
                    getDaysArray(e.fromDate, new Date(new Date(e.toDate) - 1)).map(day => {
                        tempBookedDays.push(day);
                    })
                });
                setBookedDays(tempBookedDays);
            }).catch(error => {
                alert('Der Buchungsserver ist aktuell nicht erreichbar, die Belegung wird also nicht korrekt angezeigt. Bitte kontaktieren Sie uns.')
                console.error('Error:', error);
            })

            

        }
        fetchBookings();
    }, [])


    return (
        <div id='bookingDiv' className='bookingDiv' style={{...divStyle, paddingBottom: "10px"}}>
            <p className='title aboutTitle'>Buchen</p>
            <p className='bookingPrice'>Preis pro Nacht: 136€ / Preis pro Woche 950€</p>
            <p className='bookingText'>An grau hinterlegten Daten ist das Ferienhaus bereits reserviert</p>
            <div style={{width: "80%", marginLeft: "10%"}}>
                <BookingCalendar disableHistory bookings={bookedDays} />
            </div>
            

        </div>
    )
}
