import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


export const Gallery = ({divStyle}) => {

    const images = [
        {
            original: "https://ferienhaus-hitra.com/media/Ansicht_Haus_1.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Ansicht_Haus_1.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/Ansicht_Haus_2.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Ansicht_Haus_2.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/Bootsanlegeplatz.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Bootsanlegeplatz.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/Bootsanlegeplatz_1.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Bootsanlegeplatz_1.jpg",
        },

        {
            original: "https://ferienhaus-hitra.com/media/Bad_1.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Bad_1.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/Bad_2.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Bad_2.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/Bad_3.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Bad_3.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/Esszimmer_Fjord.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Esszimmer_Fjord.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/Küche_1.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Küche_1.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/Küche_2.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Küche_2.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/SchlafzimmerA1.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/SchlafzimmerA1.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/SchlafzimmerA2.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/SchlafzimmerA2.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/SchlafzimmerB1.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/SchlafzimmerB1.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/SchlafzimmerB2.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/SchlafzimmerB2.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/Wohnzimmer1.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Wohnzimmer1.jpg",
        },
        {
            original: "https://ferienhaus-hitra.com/media/Wohnzimmer2.jpg",
            thumbnail: "https://ferienhaus-hitra.com/media/Wohnzimmer2.jpg",
        },

    ];
    
    return (
        <div id='galleryDiv' className='galleryDiv' style={{...divStyle, paddingBottom: "10px"}}>
            <p className='title aboutTitle'>Galerie</p>
            <ImageGallery items={images} />
        </div>
    )
}
